<template>
    <div>
        <modal :name="modal_name" class="final-modal" :class="{'modalZindex':safariTrue,'modal-fix-width':loggedInUser.role == 'customer','select-addons-modal':loggedInUser.role != 'customer'}" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title">SELECT {{productDetails.product_name}} / VARIANT</span>
                    <span @click="hide()">
                        <i class="icon icon-close pointer text-white"></i>
                    </span>
                </div>
                <div class="v-modal-body pr-1" style="max-height: 330px;overflow-x: hidden;overflow-y: auto;">
                    <div class="v-modal-layout" style="padding-right: 10px;">
                        <div class="d-flex">
                            <div class="col-md-3" v-for="(variant,index) in productDetails.variants" :key="index">
                                <div class="variants" :class="{selectVariant: variant.variant_id == selectedVariant}" @click="changeVariant(variant)">
                                    <span>{{variant.variant_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="text-center mt-5">
                            <button class="btn btn-checkout" @click="updateVariant()">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import Swal from 'sweetalert2'
import { SweetModal } from 'sweet-modal-vue'
export default {
    props:['modal_name','productDetails','source','alternateIndex'],
    data(){
        return{
            safariTrue:false,
            selectedVariant:null,
            details:{
                product_id:'',
                variant_id:'',
                quantity:null,
                alternative_id:''
            },
            selected:''
        }
    },
    components:{
        SweetModal
    },
    methods:{
        hide(){
            Swal.fire({
                title: 'Alert',
                text: 'Are you sure you Dont want to Select Product',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes'
            })
            .then(result => {
                if (result.value) {
                    this.hideConfirm()
                }
            })
        },
        hideConfirm(){
            this.selected = ''
            this.selectedVariant = null
            this.$modal.hide(this.modal_name);
            if(this.source == 'increaseCombo'){
                setTimeout(() => {
                    this.$modal.show('select-variant')
                }, 500);
            }else{
                setTimeout(() => {
                    this.$modal.show('select-combo')
                }, 500);
            }
        },
        changeVariant(v){
            this.selectedVariant = v.variant_id
            this.selected = v
        },
        updateVariant(v){
            if(this.selectedVariant != null){
                let quantity = this.selected.quantity + 1
                this.details ={
                    product_id:this.productDetails._id,
                    variant_id:this.selected.variant_id,
                    quantity:quantity,
                    alternative_id:this.selected.alternative_id
                }
                if(this.source == 'increaseCombo'){
                    this.$emit('updateSelectVariant',this.details,this.alternateIndex)
                    this.selectedVariant = null
                    this.selected = ''
                    this.details ={
                        product_id:'',
                        variant_id:'',
                        quantity:null,
                        alternative_id:''
                    }
                    this.$modal.hide(this.modal_name);
                    setTimeout(() => {
                        this.$modal.show('select-variant')
                    }, 500);
                }else{
                    this.$emit('variantUpdate',this.details,this.alternateIndex)
                    this.selectedVariant = null
                    this.selected = ''
                    this.details ={
                        product_id:'',
                        variant_id:'',
                        quantity:null,
                        alternative_id:''
                    }
                    this.$modal.hide(this.modal_name);
                    setTimeout(() => {
                        this.$modal.show('select-combo')
                    }, 500);
                }
            }else{
                this.$toasted.global.error('Please Select Variant')
            }
        }
    },
    computed:{
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        }
    },
    mounted(){
        this.selectedVariant = this.productDetails.variants[0].variant_id
        this.selected = this.productDetails.variants[0]
        this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
}
</script>
<style scoped>
.selectVariant{
    background: #00448b !important;
    border-radius: 6px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    color: #fff !important;
    font-weight: bold;
    text-transform: capitalize;
    padding: 25px;
    text-align: center;
    cursor: pointer;
}
.variants{
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    color: #00448b;
    font-weight: bold;
    text-transform: capitalize;
    padding: 25px;
    text-align: center;
    cursor: pointer;
}
.btn-checkout {
    border-radius: 23px;
    background-image: linear-gradient(to bottom, #ffb333, #e29005);
    color: #303031;
    padding: 0px 10px;
    margin: 5px;
    font-size: 13px !important;
    font-weight: 600;
    height: 3.2rem;
    line-height: 2.6rem;
    text-transform: capitalize;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}
</style>